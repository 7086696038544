import {useNavigate, useParams} from 'react-router-dom';
//store
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";

import DataLoadingPage from 'components/DataLoadingPage';
import {PATH_DASHBOARD} from "../../../routes/paths";
import {getServiceThunk} from "../../../redux/thunks/services";
import ServicesEdit from "../../../components/services/ServicesEdit";
import {getRegionListThunk} from "../../../redux/thunks/contact";

// ----------------------------------------------------------------------

export default function ServicesCreate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, currentService } = useSelector((state: RootState) => state.services);

  const { id = 0 } = useParams();
  const isEdit = currentService !== undefined;

  useEffect(() => {
      dispatch(getServiceThunk(+id));
      dispatch(getRegionListThunk());
  }, [dispatch]);

  const handleSave = () => {
    navigate(PATH_DASHBOARD.services.root);
  }

  return (
      <DataLoadingPage
        loading={isLoading}
        title='Редактирование услуги'
        heading={!isEdit ? 'Создание услуги' : 'Редактирование услуги'}
        links={[
          { name: 'Главная', href: PATH_DASHBOARD.root },
          { name: 'Услуги', href: PATH_DASHBOARD.services.root },
          { name: !isEdit ? 'Новая услуга' : (currentService?.title || '') }
        ]}
      >
        <ServicesEdit
            onSave={handleSave}
        />
      </DataLoadingPage>
  );
}
