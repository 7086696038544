import { createSlice } from '@reduxjs/toolkit';
import {FullService, Service} from "../../@types/services";

// ----------------------------------------------------------------------

type ServicesState = {
  isLoading: boolean;
  error: boolean;
  serviceList: Service[];
  currentService?: FullService;
};

const initialState: ServicesState = {
  isLoading: false,
  error: false,
  serviceList: [],
};

const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getServiceListSuccess(state, action) {
      state.isLoading = false;
      state.serviceList = action.payload;
    },

    getServiceSuccess(state, action) {
      state.isLoading = false;
      state.currentService = action.payload;
    },

    changeServiceActiveStatus(state, action) {
      const list = state.serviceList.map(item => {
        const active = (item.id !== +action.payload) ? item.active : (item.active ? 0 : 1);
        return {
          ... item,
          active
        }
      });
      state.serviceList = list;
    },

    deleteService(state, action) {
      const list = state.serviceList.filter((item) => item.id !== +action.payload);
      state.serviceList = list;
    },

    updateServiceSortOrder(state, action) {
      const sort: number[] = action.payload;
      const list: Service[] = [];
      sort.forEach((value) => {
        const item = state.serviceList.find((item) => item.id === value);
        if(item !== undefined) list.push(item);
      });
      state.serviceList = list;
    }
  }
});

export const { startLoading, hasError,
  getServiceListSuccess, getServiceSuccess, changeServiceActiveStatus, deleteService, updateServiceSortOrder
} = slice.actions;

export default slice.reducer;


