import React, { useState } from 'react';
// material
import {
    Link,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination, TableHead, Switch, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog
} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import Scrollbar from '../../components/Scrollbar';
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from '@mui/icons-material/Menu';
import {
    DragDropContext, Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided, DropResult, ResponderProvided
} from "react-beautiful-dnd";
import {DndTableItem} from "../../@types/dndtable";

type Props = {
    list: DndTableItem[];
    showRegions: boolean;
    handleOnDelete: (id: number) => void;
    handleOnChangeActive: (id: number, checked: boolean) => void;
    handleOnChangeSort: (sort: number[]) => void;
    itemPath: string;
    createContentCell?: (row: DndTableItem) => JSX.Element;
};

export default function DnDTable({list, showRegions, handleOnDelete, handleOnChangeActive, handleOnChangeSort, itemPath, createContentCell}:Props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<DndTableItem | undefined>(undefined);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 20));
        setPage(0);
    };

    const handleDelete = (item: DndTableItem) => {
        setOpenDeleteConfirm(item);
    }

    const handleDeleteClose = async (confirm: boolean) => {
        const id = openDeleteConfirm?.id;
        setOpenDeleteConfirm(undefined);
        if(confirm && id !== undefined){
            handleOnDelete(id);
        }
    }

    const handleActiveChange = async (item: DndTableItem, checked: boolean) => {
        handleOnChangeActive(item.id, checked);
    };

    const handleDragEnd = async (dropResult: DropResult, provided?: ResponderProvided) => {
        if (!dropResult.destination) {
            return;
        }

        if (dropResult.destination.index === dropResult.source.index) {
            return;
        }

        const sort = list.map((item) => item.id);
        const src = page * rowsPerPage + dropResult.source.index;
        const dest = page * rowsPerPage + dropResult.destination.index;
        const removed = sort.splice(src, 1);
        sort.splice(dest, 0, removed[0]);
        handleOnChangeSort(sort);
    };

    return (
        <>
            <Dialog open={openDeleteConfirm !== undefined} onClose={() => handleDeleteClose(false)}>
                <DialogTitle>Удалить объект?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы действительно хотите удалить объект "{openDeleteConfirm?.title}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteClose(false)} autoFocus>Отмена</Button>
                    <Button onClick={() => handleDeleteClose(true)}>Подтвердить</Button>
                </DialogActions>
            </Dialog>

            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="30px">&nbsp;</TableCell>
                                    <TableCell>Заголовок</TableCell>
                                    {showRegions && (<TableCell>Город</TableCell>)}
                                    <TableCell align="right">Активность</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppable" direction="vertical">
                                    {(droppableProvided: DroppableProvided) => (
                                        <TableBody
                                            ref={droppableProvided.innerRef}
                                            {...droppableProvided.droppableProps}
                                        >
                                            {list
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, i) => (
                                                    <Draggable
                                                        key={row.id}
                                                        draggableId={''+row.id}
                                                        index={i}
                                                    >
                                                        {(
                                                            draggableProvided: DraggableProvided,
                                                            snapshot: DraggableStateSnapshot
                                                        ) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    key={row.id}
                                                                    tabIndex={-1}
                                                                    role="checkbox"
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.draggableProps}
                                                                    style={{
                                                                        ...draggableProvided.draggableProps.style,
                                                                        background: snapshot.isDragging
                                                                            ? "rgba(245,245,245, 0.75)"
                                                                            : "none"
                                                                    }}
                                                                >
                                                                    <TableCell align="left" style={{verticalAlign: "top"}}>
                                                                        <div {...draggableProvided.dragHandleProps}>
                                                                            <MenuIcon/>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" padding="none" style={{verticalAlign: "top", paddingTop:"18px"}}>
                                                                        {createContentCell ? (createContentCell(row)) : (
                                                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                                                <Link underline="none" style={{color: "black"}} component={RouterLink} to={`${itemPath}/${row.id}/edit`}>
                                                                                    <Typography variant="subtitle2" noWrap>
                                                                                        {row.title}
                                                                                    </Typography>
                                                                                </Link>
                                                                            </Stack>
                                                                        )}
                                                                    </TableCell>
                                                                    {showRegions && (
                                                                        <TableCell align="left">
                                                                            {row.regions && row.regions.length > 0 ? (row.regions.map(region => region.title).join(', ')) : "Все"}
                                                                        </TableCell>
                                                                    )}
                                                                    <TableCell align="right" style={{verticalAlign: "top"}}>
                                                                        <Switch checked={!!row.active} onChange={(e, checked) => handleActiveChange(row, checked)} />
                                                                    </TableCell>
                                                                    <TableCell align="right" style={{verticalAlign: "top", paddingTop:"24px"}}>
                                                                        <div onClick={(e) => handleDelete(row)}>
                                                                            <DeleteIcon sx={{cursor: 'pointer'}}/>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }}
                                                    </Draggable>
                                                ))
                                            }
                                            {droppableProvided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={list.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, page) => setPage(page)}
                    onRowsPerPageChange={(e) => handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
}
