import { Suspense, lazy } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// layouts
import MainLayout from '../layouts/main'
import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
// guards
import GuestGuard from '../guards/GuestGuard'
import AuthGuard from '../guards/AuthGuard'
// components
import LoadingScreen from '../components/LoadingScreen'
import ServicesList from '../pages/dashboard/services/ServicesList'
import ServicesCreate from '../pages/dashboard/services/ServicesCreate'
import BannersList from '../pages/dashboard/banners/BannersList'
import BannersCreate from '../pages/dashboard/banners/BannersCreate'
import AdsList from '../pages/dashboard/ads/AdsList'
import AdsCreate from '../pages/dashboard/ads/AdsCreate'
import VacanciesList from '../pages/dashboard/vacancies/VacanciesList'
import VacanciesCreate from '../pages/dashboard/vacancies/VacanciesCreate'
import SpecialityCreate from '../pages/dashboard/vacancies/SpecialityCreate'
import AnalyticsList from '../pages/dashboard/analytics/AnalyticsList'
import AnalyticsCreate from '../pages/dashboard/analytics/AnalyticsCreate'
import ArticlesCreate from '../pages/dashboard/articles/ArticlesCreate'
import PublicationsList from '../pages/dashboard/publications/PublicationsList'
import PublicationsCreate from '../pages/dashboard/publications/PublicationsCreate'
import ArticlesList from '../pages/dashboard/articles/ArticlesList'
import LogsList from 'pages/dashboard/logs/LogsList'

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()
  const isDashboard = pathname.includes('/dashboard')

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/user/account" replace /> },
        {
          path: 'structure',
          children: [
            { element: <PageStructure /> },
            { path: 'new/:type', element: <PageStructureCreate /> },
            { path: ':id/edit', element: <PageStructureCreate /> },
          ],
        },
        { path: 'variable', element: <VariableList /> },
        { path: 'robots', element: <Robots /> },
        { path: 'sitemap', element: <Sitemap /> },
        {
          path: 'catalog',
          children: [
            { element: <CatalogCategoryList /> },
            { path: 'new', element: <CatalogCategoryCreate /> },
            { path: ':id/edit', element: <CatalogCategoryCreate /> },
            { path: 'item/:id/edit', element: <CatalogItemCreate /> },
          ],
        },

        {
          path: 'tag',
          children: [
            { element: <TagList /> },
            { path: 'new', element: <TagCreate /> },
            { path: ':id/edit', element: <TagCreate /> },
          ],
        },
        {
          path: 'landings',
          children: [
            { element: <LandingsList /> },
            { path: 'new', element: <LandingsCreate /> },
            { path: ':id/edit', element: <LandingsCreate /> },
          ],
        },
        {
          path: 'news',
          children: [
            { element: <NewsList /> },
            { path: 'new', element: <NewsCreate /> },
            { path: ':id/edit', element: <NewsCreate /> },
            { path: 'category/new', element: <NewsCategoryCreate /> },
            { path: 'category/:id/edit', element: <NewsCategoryCreate /> },
          ],
        },
        {
          path: 'analytics',
          children: [
            { element: <AnalyticsList /> },
            { path: 'new', element: <AnalyticsCreate /> },
            { path: ':id/edit', element: <AnalyticsCreate /> },
          ],
        },
        {
          path: 'articles',
          children: [
            { element: <ArticlesList /> },
            { path: 'new', element: <ArticlesCreate /> },
            { path: ':id/edit', element: <ArticlesCreate /> },
          ],
        },
        {
          path: 'publications',
          children: [
            { element: <PublicationsList /> },
            { path: 'new', element: <PublicationsCreate /> },
            { path: ':id/edit', element: <PublicationsCreate /> },
          ],
        },
        {
          path: 'rewards',
          children: [
            { element: <RewardsList /> },
            { path: 'new', element: <RewardsCreate /> },
            { path: ':id/edit', element: <RewardsCreate /> },
          ],
        },
        {
          path: 'suppliers',
          children: [
            { element: <SuppliersList /> },
            { path: 'new', element: <SuppliersCreate /> },
            { path: ':id/edit', element: <SuppliersCreate /> },
          ],
        },
        {
          path: 'services',
          children: [
            { element: <ServicesList /> },
            { path: 'new', element: <ServicesCreate /> },
            { path: ':id/edit', element: <ServicesCreate /> },
          ],
        },
        {
          path: 'banners',
          children: [
            { element: <BannersList /> },
            { path: 'new', element: <BannersCreate /> },
            { path: ':id/edit', element: <BannersCreate /> },
          ],
        },
        {
          path: 'ads',
          children: [
            { element: <AdsList /> },
            { path: 'new', element: <AdsCreate /> },
            { path: ':id/edit', element: <AdsCreate /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'client',
          children: [
            { element: <ClientList /> },
            { path: 'list', element: <ClientList /> },
            { path: ':id/edit', element: <ClientCreate /> },
          ],
        },
        {
          path: 'company',
          children: [
            { element: <CompanyList /> },
            { path: 'list', element: <CompanyList /> },
            { path: ':id/edit', element: <CompanyCreate /> },
          ],
        },
        {
          path: 'contact',
          children: [
            { element: <RegionList /> },
            { path: 'new', element: <RegionCreate /> },
            { path: ':id/edit', element: <RegionCreate /> },
            { path: ':regionId/new', element: <ContactCreate /> },
            { path: ':regionId/:id/edit', element: <ContactCreate /> },
          ],
        },
        {
          path: 'vacancies',
          children: [
            { element: <VacanciesList /> },
            { path: 'new', element: <VacanciesCreate /> },
            { path: ':id/edit', element: <VacanciesCreate /> },
            { path: 'speciality/new', element: <SpecialityCreate /> },
            { path: 'speciality/:id/edit', element: <SpecialityCreate /> },
          ],
        },
        {
          path: 'logs',
          children: [{ element: <LogsList /> }],
        },

        {
          path: 'hr',
          children: [
            {
              path: 'page',
              children: [
                { element: <HrPage /> },
                { path: 'new/', element: <HrPageCreate /> },
                { path: ':id/edit', element: <HrPageCreate /> },
              ],
            },
            {
              path: 'event',
              children: [
                { element: <HrEventList /> },
                { path: 'new/', element: <HrEventCreate /> },
                { path: ':url/edit', element: <HrEventCreate /> },
              ],
            },
            {
              path: 'article',
              children: [
                { element: <HrArticleList /> },
                { path: 'new/', element: <HrArticleCreate /> },
                { path: ':url/edit', element: <HrArticleCreate /> },
              ],
            },
            {
              path: 'vacancy',
              children: [
                { element: <HrVacancyList /> },
                { path: 'new/', element: <HrVacancyCreate /> },
                { path: ':url/edit', element: <HrVacancyCreate /> },
              ],
            },
            {
              path: 'manager',
              children: [
                { element: <HrManagersList /> },
                { path: 'new/', element: <HrManagersCreate /> },
                { path: ':url/edit', element: <HrManagersCreate /> },
              ],
            },
            {
              path: 'success-story',
              children: [
                { element: <HrSuccessStoryList /> },
                { path: 'new/', element: <HrSuccessStoryCreate /> },
                { path: ':url/edit', element: <HrSuccessStoryCreate /> },
              ],
            },
            {
              path: 'specialities',
              children: [
                { element: <HrSpecialitiesList /> },
                { path: 'new/', element: <HrSpecialitiesCreate /> },
                { path: ':url/edit', element: <HrSpecialitiesCreate /> },
              ],
            },
            {
              path: 'city',
              children: [
                { element: <HrCityList /> },
                { path: 'new/', element: <HrCityCreate /> },
                { path: ':url/edit', element: <HrCityCreate /> },
              ],
            },
            {
              path: 'directions',
              children: [
                { element: <HrDirectionsList /> },
                { path: ':url/edit', element: <HrDirectionsEdit /> },
              ],
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')))
const ResetPassword = Loadable(
  lazy(() => import('../pages/authentication/ResetPassword')),
)
// Dashboard
const PageStructure = Loadable(
  lazy(() => import('../pages/dashboard/PageStructure')),
)
const PageStructureCreate = Loadable(
  lazy(() => import('../pages/dashboard/PageStructureCreate')),
)
const VariableList = Loadable(
  lazy(() => import('../pages/dashboard/VariableList')),
)
const Robots = Loadable(lazy(() => import('../pages/dashboard/Robots')))
const Sitemap = Loadable(lazy(() => import('../pages/dashboard/Sitemap')))
const CatalogCategoryList = Loadable(
  lazy(() => import('../pages/dashboard/catalog/CategoryList')),
)
const CatalogCategoryCreate = Loadable(
  lazy(() => import('../pages/dashboard/catalog/CategoryCreate')),
)
const TagList = Loadable(lazy(() => import('../pages/dashboard/tag/TagList')))
const TagCreate = Loadable(
  lazy(() => import('../pages/dashboard/tag/TagCreate')),
)
const LandingsList = Loadable(
  lazy(() => import('../pages/dashboard/landings/LandingsList')),
)
const LandingsCreate = Loadable(
  lazy(() => import('../pages/dashboard/landings/LandingsCreate')),
)
const CatalogItemCreate = Loadable(
  lazy(() => import('../pages/dashboard/catalog/ItemCreate')),
)
const NewsList = Loadable(
  lazy(() => import('../pages/dashboard/news/NewsList')),
)
const NewsCreate = Loadable(
  lazy(() => import('../pages/dashboard/news/NewsCreate')),
)
const NewsCategoryCreate = Loadable(
  lazy(() => import('../pages/dashboard/news/CategoryCreate')),
)
const RewardsList = Loadable(
  lazy(() => import('../pages/dashboard/rewards/RewardsList')),
)
const RewardsCreate = Loadable(
  lazy(() => import('../pages/dashboard/rewards/RewardsCreate')),
)
const SuppliersList = Loadable(
  lazy(() => import('../pages/dashboard/suppliers/SuppliersList')),
)
const SuppliersCreate = Loadable(
  lazy(() => import('../pages/dashboard/suppliers/SuppliersCreate')),
)
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')))
const UserAccount = Loadable(
  lazy(() => import('../pages/dashboard/UserAccount')),
)
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')))
const RegionList = Loadable(
  lazy(() => import('../pages/dashboard/contact/RegionList')),
)
const RegionCreate = Loadable(
  lazy(() => import('../pages/dashboard/contact/RegionCreate')),
)
const ContactCreate = Loadable(
  lazy(() => import('../pages/dashboard/contact/ContactCreate')),
)
const ClientCreate = Loadable(
  lazy(() => import('../pages/dashboard/client/ClientCreate')),
)
const ClientList = Loadable(
  lazy(() => import('../pages/dashboard/client/ClientList')),
)
const CompanyCreate = Loadable(
  lazy(() => import('../pages/dashboard/company/CompanyCreate')),
)
const CompanyList = Loadable(
  lazy(() => import('../pages/dashboard/company/CompanyList')),
)

// Main
const About = Loadable(lazy(() => import('../pages/About')))
const Contact = Loadable(lazy(() => import('../pages/Contact')))
const Faqs = Loadable(lazy(() => import('../pages/Faqs')))
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')))
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')))
const Pricing = Loadable(lazy(() => import('../pages/Pricing')))
const Payment = Loadable(lazy(() => import('../pages/Payment')))
const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const NotFound = Loadable(lazy(() => import('../pages/Page404')))

// Dashboard HR

const HrPage = Loadable(lazy(() => import('../pages/HrDashboard/pages/HrPage')))
const HrPageCreate = Loadable(
  lazy(() => import('../pages/HrDashboard/pages/HrPageCreate')),
)
const HrEventList = Loadable(
  lazy(() => import('../pages/HrDashboard/event/EventList')),
)
const HrEventCreate = Loadable(
  lazy(() => import('../pages/HrDashboard/event/EventCreate')),
)
const HrArticleList = Loadable(
  lazy(() => import('../pages/HrDashboard/articles/ArticlesList')),
)
const HrArticleCreate = Loadable(
  lazy(() => import('../pages/HrDashboard/articles/ArticlesCreate')),
)
const HrVacancyList = Loadable(
  lazy(() => import('../pages/HrDashboard/vacancies/VacanciesHrList')),
)
const HrVacancyCreate = Loadable(
  lazy(() => import('../pages/HrDashboard/vacancies/VacanciesHrCreate')),
)
const HrManagersList = Loadable(
  lazy(() => import('../pages/HrDashboard/managers/ManagersHrList')),
)
const HrManagersCreate = Loadable(
  lazy(() => import('../pages/HrDashboard/managers/ManagersHrCreate')),
)
const HrSuccessStoryList = Loadable(
  lazy(() => import('../pages/HrDashboard/successStory/SuccessStoryList')),
)
const HrSuccessStoryCreate = Loadable(
  lazy(() => import('../pages/HrDashboard/successStory/SuccessStoryCreate')),
)
const HrSpecialitiesList = Loadable(
  lazy(() => import('../pages/HrDashboard/specialities/SpecialitiesList')),
)
const HrSpecialitiesCreate = Loadable(
  lazy(() => import('../pages/HrDashboard/specialities/SpecialitiesCreate')),
)
const HrCityList = Loadable(
  lazy(() => import('../pages/HrDashboard/city/CityHrList')),
)
const HrCityCreate = Loadable(
  lazy(() => import('../pages/HrDashboard/city/CityHrCreate')),
)
const HrDirectionsList = Loadable(
  lazy(() => import('../pages/HrDashboard/directions/DirectionsList')),
)
const HrDirectionsEdit = Loadable(
  lazy(() => import('../pages/HrDashboard/directions/DirectionsEdit')),
)
