// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  client: {
    root: path(ROOTS_DASHBOARD, '/client/'),
  },
  structure: {
    root: path(ROOTS_DASHBOARD, '/structure'),
    newPage: path(ROOTS_DASHBOARD, '/structure/new'),
  },
  variable: path(ROOTS_DASHBOARD, '/variable'),
  robots: path(ROOTS_DASHBOARD, '/robots'),
  sitemap: path(ROOTS_DASHBOARD, '/sitemap'),
  catalog: {
    root: path(ROOTS_DASHBOARD, '/catalog'),
    create: path(ROOTS_DASHBOARD, '/catalog/new'),
    item: path(ROOTS_DASHBOARD, '/catalog/item'),
  },
  news: {
    root: path(ROOTS_DASHBOARD, '/news'),
    create: path(ROOTS_DASHBOARD, '/news/new'),
    createCategory: path(ROOTS_DASHBOARD, '/news/category/new'),
  },
  tag: {
    root: path(ROOTS_DASHBOARD, '/tag'),
    create: path(ROOTS_DASHBOARD, '/tag/new'),
  },
  landings: {
    root: path(ROOTS_DASHBOARD, '/landings'),
    create: path(ROOTS_DASHBOARD, '/landings/new'),
  },
  analytics: {
    root: path(ROOTS_DASHBOARD, '/analytics'),
    create: path(ROOTS_DASHBOARD, '/analytics/new'),
  },
  articles: {
    root: path(ROOTS_DASHBOARD, '/articles'),
    create: path(ROOTS_DASHBOARD, '/articles/new'),
  },
  publications: {
    root: path(ROOTS_DASHBOARD, '/publications'),
    create: path(ROOTS_DASHBOARD, '/publications/new'),
  },
  rewards: {
    root: path(ROOTS_DASHBOARD, '/rewards'),
    create: path(ROOTS_DASHBOARD, '/rewards/new'),
  },
  suppliers: {
    root: path(ROOTS_DASHBOARD, '/suppliers'),
    create: path(ROOTS_DASHBOARD, '/suppliers/new'),
  },
  services: {
    root: path(ROOTS_DASHBOARD, '/services'),
    create: path(ROOTS_DASHBOARD, '/services/new'),
  },
  banners: {
    root: path(ROOTS_DASHBOARD, '/banners'),
    create: path(ROOTS_DASHBOARD, '/banners/new'),
  },
  ads: {
    root: path(ROOTS_DASHBOARD, '/ads'),
    create: path(ROOTS_DASHBOARD, '/ads/new'),
  },
  contact: {
    root: path(ROOTS_DASHBOARD, '/contact'),
    createRegion: path(ROOTS_DASHBOARD, '/contact/new'),
    createContact: path(ROOTS_DASHBOARD, '/contact/0/new'),
  },
  vacancies: {
    root: path(ROOTS_DASHBOARD, '/vacancies'),
    create: path(ROOTS_DASHBOARD, '/vacancies/new'),
    createSpeciality: path(ROOTS_DASHBOARD, '/vacancies/speciality/new'),
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company/'),
  },
  logs: {
    root: path(ROOTS_DASHBOARD, '/logs/'),
  },

  hr: {
    page: {
      root: path(ROOTS_DASHBOARD, '/hr/page'),
      newPage: path(ROOTS_DASHBOARD, '/hr/page/new'),
    },
    event: {
      root: path(ROOTS_DASHBOARD, '/hr/event'),
      newEvent: path(ROOTS_DASHBOARD, '/hr/event/new'),
    },
    article: {
      root: path(ROOTS_DASHBOARD, '/hr/article'),
      newArticle: path(ROOTS_DASHBOARD, '/hr/article/new'),
    },
    vacancy: {
      root: path(ROOTS_DASHBOARD, '/hr/vacancy'),
      new: path(ROOTS_DASHBOARD, '/hr/vacancy/new'),
    },
    manager: {
      root: path(ROOTS_DASHBOARD, '/hr/manager'),
      new: path(ROOTS_DASHBOARD, '/hr/manager/new'),
    },
    successStory: {
      root: path(ROOTS_DASHBOARD, '/hr/success-story'),
      new: path(ROOTS_DASHBOARD, '/hr/success-story/new'),
    },
    specialities: {
      root: path(ROOTS_DASHBOARD, '/hr/specialities'),
      new: path(ROOTS_DASHBOARD, '/hr/specialities/new'),
    },
    city: {
      root: path(ROOTS_DASHBOARD, '/hr/city'),
      new: path(ROOTS_DASHBOARD, '/hr/city/new'),
    },
    direction: {
      root: path(ROOTS_DASHBOARD, '/hr/directions'),
    },
  },
}

