import React, {useRef} from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface TinyMCEEditorProps {
    value: string;
    onChange: (value: string) => void;
}

export default function TinyMCEEditor({
    value,
    onChange,
}: TinyMCEEditorProps) {

    const apiKey = process.env.REACT_APP_TINY_API_KEY
    const editorRef = useRef<Editor>(null);

    const handleEditorChange = (e: any) => {
        onChange(e.target.getContent());
    }

    const handleImageUpload = (input: HTMLInputElement, callback: any) => {
        if(input.files){
            const file = input.files[0];
            const reader = new FileReader();
            reader.onload = function () {
                const id = 'blobid' + (new Date()).getTime();
                const blobCache =  editorRef.current?.editor?.editorUpload.blobCache
                const base64 = reader.result?.toString().split(',')[1];
                const blobInfo = blobCache?.create(id, file, base64);
                if(blobInfo != undefined){
                    blobCache?.add(blobInfo);
                    callback(blobInfo.blobUri(), { title: file.name });
                }
            };
            reader.readAsDataURL(file);
        }
    }

    const handleOpenImageInput = (callback: any, value: any, meta: any) => {
        const input = document.createElement('input') as HTMLInputElement;
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = (e: Event) => handleImageUpload(e.target as HTMLInputElement, callback);
        input.click();
    }

    return (
      <>
          <Editor
              ref={editorRef}
              apiKey={apiKey}
              initialValue={value}
              init={{
                  height: 500,
                  menubar: true,
                  plugins: [
                      'advlist autolink lists link image',
                      'charmap print preview anchor help',
                      'searchreplace visualblocks code',
                      'insertdatetime media table paste'
                  ],
                  toolbar:
                      'undo redo | formatselect | bold italic | \
                      alignleft aligncenter alignright | \
                      bullist numlist outdent indent | help',
                  language: 'ru',
                  file_picker_types: 'image',
                  file_picker_callback: handleOpenImageInput,
                  content_style: 'body { font-family: "Montserrat",sans-serif; }',
              }}
              onChange={handleEditorChange}
          />
      </>
  );
}
