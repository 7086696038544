import {
    Box, Button,
    Card, Checkbox,
    FormControlLabel, FormGroup, FormHelperText,
    Grid,
    Switch, Tab, Tabs,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState, useCallback} from "react";
import {RootState, useDispatch, useSelector} from "../../redux/store";
import {uploadFileThunk} from "../../redux/thunks/files";

import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {baseUrl} from "../../utils/axios";
import {createServiceThunk, editServiceThunk} from "../../redux/thunks/services";
import TinyMCEEditor from "../editor/tinymce";
import {UploadSingleFile} from "../upload";
import {cloneDeep} from "lodash";

type FormValuesProps = {
    title: string;
    sortOrder: number;
    active: boolean;
    staticPath: string;
    content: string;
    metaH1: string;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
};

type Props = {
    onSave: () => void;
};

export default function ServicesEdit({ onSave }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { currentService } = useSelector((state: RootState) => state.services);
    const { regionList } = useSelector((state: RootState) => state.contact);

    const isEdit = currentService !== undefined;

    const [currentTab, setCurrentTab] = useState<string>('general');
    const [content, setContent] = useState<string>('');
    const [file, setFile] = useState<File | string | null>(null);
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [selectedRegions, setSelectedRegions] = useState<Set<number>>(new Set());

    const EditSchema = Yup.object().shape({
        title: Yup.string().required('Введите заголовок'),
    });

    const formik = useFormik<FormValuesProps>({
        initialValues: {
            title: '',
            active: true,
            sortOrder: 0,
            staticPath: '',
            content: '',
            metaH1: '',
            metaTitle: '',
            metaKeywords: '',
            metaDescription: '',
        },
        validationSchema: EditSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const params = {
                ...values,
                active: values.active ? 1 : 0,
                files: fileName !== undefined ? [fileName] : [],
                content: content,
                regions: Array.from(selectedRegions),
            }
            const result = isEdit
                ? await dispatch(editServiceThunk(''+currentService?.id, params))
                : await dispatch(createServiceThunk(params));

            setSubmitting(false);
            if(result) {
                resetForm();
                enqueueSnackbar('Услуга сохранена', {variant: 'success'});
                onSave();
            }
            else {
                enqueueSnackbar("Ошибка сохранения услуги", { variant: 'error' });
            }
        }
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

    useEffect(() => {
        const newSelectedRegions = new Set<number>();
        if(currentService){
            setFieldValue('title', currentService?.title);
            setFieldValue('sortOrder', currentService?.sortOrder);
            setFieldValue('active', !!currentService?.active);
            setFieldValue('staticPath', currentService?.staticPath);
            setFieldValue('active', !!currentService?.active);
            setFieldValue('content', currentService?.content);
            setFieldValue('metaH1', currentService?.metaH1);
            setFieldValue('metaTitle', currentService?.metaTitle);
            setFieldValue('metaKeywords', currentService?.metaKeywords);
            setFieldValue('metaDescription', currentService?.metaDescription);
            const files = currentService?.files;
            if(files?.length > 0){
                const currentFileName = files[0].file;
                setFileName(currentFileName);
                setFile(`${baseUrl}/files/service/${currentFileName}`);
            }
            else {
                setFile(null);
                setFileName(undefined);
            }
            setContent(currentService?.content);
            currentService?.regions?.forEach(region => {
                newSelectedRegions.add(region.id);
            });
        }
        else {
            resetForm();
            setFile(null);
            setFileName(undefined);
            setContent('');
        }
        setSelectedRegions(newSelectedRegions);
    }, [currentService]);

    const handleDropFile = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const loadedFileName = await dispatch(uploadFileThunk(file));
            if(loadedFileName){
                setFileName(loadedFileName);
                setFile({
                    ...file,
                    preview: URL.createObjectURL(file)
                });
            }
            else {
                enqueueSnackbar("Ошибка загрузки файла", { variant: 'error' });
            }
        }
    }, []);

    const handleRemoveImage = useCallback(() => {
        setFile(null);
        setFileName(undefined);
    }, []);

    const handleRegionChange = (regionId: number, checked: boolean) => {
        const newSelectedRegions = cloneDeep(selectedRegions);
        if(regionId === 0) {
            if(checked) {
                newSelectedRegions.clear();
            }
        }
        else {
            if(checked) {
                newSelectedRegions.add(regionId);
            }
            else {
                newSelectedRegions.delete(regionId);
            }
        }

        setSelectedRegions(newSelectedRegions);
    }

    const PAGE_TABS = [
        {
            value: 'general',
            title: 'Содержание',
            component:
                <Card sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                {...getFieldProps('title')}
                                fullWidth
                                type="text"
                                label="Заголовок"
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('staticPath')}
                                fullWidth
                                type="text"
                                label="URL услуги"
                                error={Boolean(touched.staticPath && errors.staticPath)}
                                helperText={touched.staticPath && errors.staticPath}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TinyMCEEditor
                                value={values.content}
                                onChange={(val) => setContent(val)}
                            />
                            {touched.content && errors.content && (
                                <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                    {touched.content && errors.content}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch {...getFieldProps('active')} checked={values.active} />}
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Активность
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />
                            <Box sx={{ mb: 2 }} />
                            <UploadSingleFile file={file} onDrop={handleDropFile} />
                            {file && (
                                <Button color="error" onClick={handleRemoveImage}>удалить</Button>
                            )}
                            <Box sx={{ mb: 2 }} />
                            <FormGroup>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Регионы:
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedRegions.size === 0}
                                            onChange={e => handleRegionChange(0, e.target.checked)}
                                        />
                                    }
                                    label="Все города"
                                />
                                {regionList.map(region => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedRegions.has(region.id)}
                                                onChange={e => handleRegionChange(region.id, e.target.checked)}
                                            />
                                        }
                                        label={region.title}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Card>
        },
        {
            value: 'seo',
            title: 'SEO',
            component:
                <Card sx={{ p: 3 }}>
                    <TextField
                        {...getFieldProps('metaH1')}
                        fullWidth
                        type="text"
                        label="Заголовок H1"
                        error={Boolean(touched.metaH1 && errors.metaH1)}
                        helperText={touched.metaH1 && errors.metaH1}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaTitle')}
                        fullWidth
                        type="text"
                        label="Meta Title"
                        error={Boolean(touched.metaTitle && errors.metaTitle)}
                        helperText={touched.metaTitle && errors.metaTitle}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaKeywords')}
                        fullWidth
                        type="text"
                        label="Meta Keywords"
                        error={Boolean(touched.metaKeywords && errors.metaKeywords)}
                        helperText={touched.metaKeywords && errors.metaKeywords}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaDescription')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={4}
                        label="Meta Description"
                        error={Boolean(touched.metaDescription && errors.metaDescription)}
                        helperText={touched.metaDescription && errors.metaDescription}
                    />
                </Card>
        }
    ];

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {PAGE_TABS.map((tab) => (
                        <Tab
                            disableRipple
                            key={tab.value}
                            label={tab.title}
                            value={tab.value}
                        />
                    ))}
                </Tabs>

                {PAGE_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}

                <Box sx={{ mb: 5 }} />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Сохранить
                </LoadingButton>
                <Button color="inherit" variant="contained"  sx={{mx: 2}} onClick={onSave}>
                    Отмена
                </Button>
            </Form>
        </FormikProvider>
    );
}