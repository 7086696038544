import { createSlice } from '@reduxjs/toolkit';
import {FullRewardsItem, RewardsItem} from "../../@types/rewards";

// ----------------------------------------------------------------------

type RewardsState = {
  isLoading: boolean;
  error: boolean;
  rewardsList: RewardsItem[];
  currentRewardsItem?: FullRewardsItem;
};

const initialState: RewardsState = {
  isLoading: false,
  error: false,
  rewardsList: [],
};

const slice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getRewardsListSuccess(state, action) {
      state.isLoading = false;
      state.rewardsList = action.payload;
    },

    getRewardsItemSuccess(state, action) {
      state.isLoading = false;
      state.currentRewardsItem = action.payload;
    },

    changeRewardsItemActiveStatus(state, action) {
      const list = state.rewardsList.map(rewardsItem => {
        const active = (rewardsItem.id !== action.payload) ? rewardsItem.active : (rewardsItem.active ? 0 : 1);
        return {
          ... rewardsItem,
          active
        }
      });
      state.rewardsList = list;
    },

    deleteRewardsItem(state, action) {
      const list = state.rewardsList.filter((rewardsItem) => rewardsItem.id !== action.payload);
      state.rewardsList = list;
    },

    updateRewardSortOrder(state, action) {
      const sort: number[] = action.payload;
      const list: RewardsItem[] = [];
      sort.forEach((value) => {
        const item = state.rewardsList.find((item) => item.id === value);
        if(item !== undefined) list.push(item);
      });
      state.rewardsList = list;
    }
  }
});

export const { startLoading, hasError,
  getRewardsListSuccess, getRewardsItemSuccess, changeRewardsItemActiveStatus, deleteRewardsItem, updateRewardSortOrder,
} = slice.actions;

export default slice.reducer;


