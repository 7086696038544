import React, { useEffect } from 'react';
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store';
import {PATH_DASHBOARD} from "../../routes/paths";
import {useSnackbar} from "notistack";
import DnDTable from "../dndtable/DnDTable";
import {
    changeActiveServiceThunk,
    deleteServiceThunk,
    getServiceListThunk,
    updateServiceSortThunk
} from "../../redux/thunks/services";
import {updateServiceSortOrder} from "../../redux/slices/services";

export default function ServicesTable() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { serviceList } = useSelector((state: RootState) => state.services);

    useEffect(() => {
        dispatch(getServiceListThunk());
    }, [dispatch]);

    const handleOnDelete = async (id: number) => {
        const result = await dispatch(deleteServiceThunk(''+id));
        if(result){
            enqueueSnackbar('Услуга удалена', {variant: 'success'});
        }
        else {
            enqueueSnackbar("Ошибка удаления услуги", { variant: 'error' });
        }
    }

    const handleOnChangeActive = async (id: number, checked: boolean) => {
        const result = await dispatch(changeActiveServiceThunk(id, checked));
        if(result) {
            enqueueSnackbar('Активность услуги изменена', {variant: 'success'});
        }
        else {
            enqueueSnackbar("Ошибка выполнения запроса на изменение активности услуги", { variant: 'error' });
        }
    }

    const handleOnChangeSort = async (sort: number[]) => {
        dispatch(updateServiceSortOrder(sort));
        const result = await dispatch(updateServiceSortThunk(sort));
        if(result) {
            enqueueSnackbar('Порядок сохранен', {variant: 'success'});
        }
        else {
            enqueueSnackbar("Ошибка выполнения запроса на сохранение порядка", { variant: 'error' });
        }
    }

    return (
        <DnDTable
            list={serviceList}
            showRegions={true}
            handleOnDelete={handleOnDelete}
            handleOnChangeActive={handleOnChangeActive}
            handleOnChangeSort={handleOnChangeSort}
            itemPath={PATH_DASHBOARD.services.root}
        />
    );
}
