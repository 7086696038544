// material
import {
  Container, Button
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {Link as RouterLink} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import ServicesTable from "../../../components/services/ServicesTable";

export default function ServicesList() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Список услуг">
      <Container maxWidth={themeStretch ? false : 'lg'}>

        <HeaderBreadcrumbs
          heading="Список услуг"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Услуги'},
          ]}
          action={
              <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${PATH_DASHBOARD.services.create}`}
                  startIcon={<Icon icon={plusFill} />}
              >
                Услуга
              </Button>
          }
        />

        <ServicesTable />

      </Container>
    </Page>
  );
}
