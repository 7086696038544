import {AppThunk} from "../store";
import axios from "../../utils/axios";
import {startLoading, hasError, deleteService, getServiceListSuccess, getServiceSuccess, changeServiceActiveStatus} from "redux/slices/services";

export const getServiceListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/services');
        dispatch(getServiceListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getServiceThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getServiceSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/services/${id}`);
            dispatch(getServiceSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createServiceThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/services`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editServiceThunk = (
    id: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/services/${id}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deleteServiceThunk = (
    id: string
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/services/${id}`);
        if(response.data.result) {
            dispatch(deleteService(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActiveServiceThunk = (
    id: number,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/services/${id}/activate`, params);
        if(response.data.result){
            dispatch(changeServiceActiveStatus(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const updateServiceSortThunk = (
    sort: number[],
):AppThunk<Promise<boolean>> => async dispatch => {
    dispatch(startLoading());
    try {
        const params = {
            sortOrder: sort,
        }
        const response = await axios.post(`/services/update_sort`, params);
        if(response.data){
            dispatch(getServiceListSuccess(response.data));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}
