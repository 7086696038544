import {
    Box, Button,
    Card,
    FormHelperText, Grid,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
    FormControlLabel,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {RootState, useDispatch, useSelector} from "../../redux/store";

import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {DatePicker, LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import Moment from "moment";
import TinyMCEEditor from "../editor/tinymce";
import {createArticlesItemThunk, editArticlesItemThunk} from "../../redux/thunks/articles";

type FormValuesProps = {
    title: string;
    author: string;
    date: Date | null;
    active: boolean;
    staticPath: string;
    content: string;
    metaH1: string;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
};

type Props = {
    onSave: () => void;
};

export default function ArticlesEdit({ onSave }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { currentArticlesItem, articlesList } = useSelector((state: RootState) => state.articles);

    const isEdit = currentArticlesItem !== undefined;

    const [currentTab, setCurrentTab] = useState<string>('general');
    const [content, setContent] = useState<string>('');

    const EditSchema = Yup.object().shape({
        title: Yup.string().required('Введите заголовок'),
        staticPath: Yup.string().required('Введите URL страницы'),
    });

    const formik = useFormik<FormValuesProps>({
        initialValues: {
            title: '',
            author: '',
            date: null,
            active: true,
            staticPath: '',
            content: '',
            metaH1: '',
            metaTitle: '',
            metaKeywords: '',
            metaDescription: '',
        },
        validationSchema: EditSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            await submitForm();
        }
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

    useEffect(() => {
        if(currentArticlesItem){
            setFieldValue('title', currentArticlesItem?.title);
            setFieldValue('author', currentArticlesItem?.author);
            setFieldValue('date', currentArticlesItem?.date);
            setFieldValue('staticPath', currentArticlesItem?.staticPath);
            setFieldValue('active', !!currentArticlesItem?.active);
            setFieldValue('content', currentArticlesItem?.content);
            setFieldValue('metaH1', currentArticlesItem?.metaH1);
            setFieldValue('metaTitle', currentArticlesItem?.metaTitle);
            setFieldValue('metaKeywords', currentArticlesItem?.metaKeywords);
            setFieldValue('metaDescription', currentArticlesItem?.metaDescription);
            setContent(currentArticlesItem?.content);
        }
        else {
            resetForm();
            let defaultInd = 1;
            while(articlesList.find(item => item.staticPath === 'articles'+defaultInd)){ defaultInd++; }
            setFieldValue('staticPath', 'articles'+defaultInd);
            setContent('');
        }
    }, [currentArticlesItem]);

    const submitForm = async () => {
        const params = {
            ...values,
            active: values.active ? 1 : 0,
            date: values.date ? Moment(values.date).format('YYYY-MM-DD') : null,
            content: content,
        }
        const result = isEdit
            ? await dispatch(editArticlesItemThunk(''+currentArticlesItem?.id, params))
            : await dispatch(createArticlesItemThunk(params));

        if(result) {
            resetForm();
            enqueueSnackbar('Статья сохранена', {variant: 'success'});
            onSave();
        }
        else {
            enqueueSnackbar("Ошибка сохранения статьи", { variant: 'error' });
        }
    }

    const PAGE_TABS = [
        {
            value: 'general',
            title: 'Содержание',
            component:
                <Card sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                {...getFieldProps('title')}
                                fullWidth
                                type="text"
                                label="Заголовок"
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('author')}
                                fullWidth
                                type="text"
                                label="Автор"
                                error={Boolean(touched.author && errors.author)}
                                helperText={touched.author && errors.author}
                            />
                            <Box sx={{ mb: 2 }} />
                            <DatePicker
                                label="Дата"
                                {...getFieldProps('date')}
                                onChange={(date) => setFieldValue('date', date)}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        error={Boolean(touched.date && errors.date)}
                                    />
                                )}
                                inputFormat="yyyy-MM-dd"
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('staticPath')}
                                fullWidth
                                type="text"
                                label="URL статьи"
                                error={Boolean(touched.staticPath && errors.staticPath)}
                                helperText={touched.staticPath && errors.staticPath}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {currentArticlesItem?.createdAt && (
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Создана {Moment(currentArticlesItem?.createdAt).format('YYYY-MM-DD HH:mm')} {currentArticlesItem?.createdUserName}
                                </Typography>
                            )}
                            {currentArticlesItem?.updatedAt && (
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Обновлена {Moment(currentArticlesItem?.updatedAt).format('YYYY-MM-DD HH:mm')} {currentArticlesItem?.updatedUserName}
                                </Typography>
                            )}
                            <Box sx={{ mb: 2 }} />
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch {...getFieldProps('active')} checked={values.active} />}
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Активность
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />
                            <Box sx={{ mb: 2 }} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TinyMCEEditor
                                value={values.content}
                                onChange={(val) => setContent(val)}
                            />
                            {touched.content && errors.content && (
                                <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                    {touched.content && errors.content}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </Card>
        },
        {
            value: 'seo',
            title: 'SEO',
            component:
                <Card sx={{ p: 3 }}>
                    <TextField
                        {...getFieldProps('metaH1')}
                        fullWidth
                        type="text"
                        label="Заголовок H1"
                        error={Boolean(touched.metaH1 && errors.metaH1)}
                        helperText={touched.metaH1 && errors.metaH1}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaTitle')}
                        fullWidth
                        type="text"
                        label="Meta Title"
                        error={Boolean(touched.metaTitle && errors.metaTitle)}
                        helperText={touched.metaTitle && errors.metaTitle}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaKeywords')}
                        fullWidth
                        type="text"
                        label="Meta Keywords"
                        error={Boolean(touched.metaKeywords && errors.metaKeywords)}
                        helperText={touched.metaKeywords && errors.metaKeywords}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaDescription')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={4}
                        label="Meta Description"
                        error={Boolean(touched.metaDescription && errors.metaDescription)}
                        helperText={touched.metaDescription && errors.metaDescription}
                    />
                </Card>
        }
    ];

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Tabs
                        value={currentTab}
                        scrollButtons="auto"
                        variant="scrollable"
                        allowScrollButtonsMobile
                        onChange={(e, value) => setCurrentTab(value)}
                    >
                        {PAGE_TABS.map((tab) => (
                            <Tab
                                disableRipple
                                key={tab.value}
                                label={tab.title}
                                value={tab.value}
                            />
                        ))}
                    </Tabs>

                    {PAGE_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })}

                    <Box sx={{ mb: 5 }} />

                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Сохранить
                    </LoadingButton>
                    <Button color="inherit" variant="contained"  sx={{mx: 2}} onClick={onSave}>
                        Отмена
                    </Button>
                </Form>
            </FormikProvider>
        </>
    );
}